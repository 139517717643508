#nav-dropdown {
	color: #a4a6b3;
}

#sidebar-wrapper {
	min-height: 100vh !important;
	margin-left: -1rem;
}

.minHeight100 {
	min-height: 100vh !important;
}

.backgroundColorBlue {
	background-color: '#1D3557' !important;
}

.colorWhite {
	color: white;
}
